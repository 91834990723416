import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";
import vi_VN from "./vi_VN";
import zh_CN from "./zh_CN";
import th_TH from "@/lang/th_TH";
import pt_br from "@/lang/pt_br";

Vue.use(VueI18n);

const messages = {
	vi_VN,
	zh_CN,
	th_TH,
	pt_br
};

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: store.state.lang, // set locale
	messages, // set locale messages
});

export default i18n;
