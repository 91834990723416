export default {
	万: "Milhão",
	确认: "confirme",
	取消: "Cancelar",
	账号: "conta",
	密码: "senha",
	确认密码: "Confirme sua senha",
	邀请码: "Código de Convite",
	公益榜: "Lista de bem-estar público",
	音乐榜: "parada musical",
	电影榜: "Lista de filmes",
	财经榜: "lista financeira",
	直播榜: "Lista de transmissão ao vivo",
	密码错误: "senha incorreta",
	选红色: "Escolha vermelho",
	选蓝色: "Escolha azul",
	币单位: "BRL",
	donation: {
		title: "doações",
		total_donations_amount: "Valor total de doação da plataforma",
		my_donation_amount: "Valor da minha doação",
		donation_count: "Número de doações",
		num: "Tempo",
		invitations_sent: "Convide amigos para participar",
		donate_now_button: "DOE agora",
		donation_amount_input: "Insira o valor da doação",
	},
	tabbar: {
		home: "Pagina inicial",
		welfare: "Mesa de caridade",
		antifraud: "Anti-fraude",
		my: "EU",
	},
	withdraw: {
		current_account_balance: "Saldo atual da conta",
		placeholder1: "Por favor, insira o valor que deseja sacar",
		bank_card_info: "Informações do cartão bancário",
		tip1: "Ainda não vinculado ao cartão bancário, vincule agora",
		receiving_bank: "Banco recebe cartão",
		receiving_account: "As contas recebem",
		receiving_name: "nome do destinatário",
		placeholder2: "Por favor, digite sua senha de retirada",
		submit_application: "Confirmar retirada",
		hint: "Conselho util",
		withdraw_time: "Tempo de retirada",
		tip2: "Você ainda não configurou sua senha de saque, acesse as configurações?",
		toast1: "A senha de retirada deve ter 6 dígitos",
		toast2: "Vincule seu cartão bancário primeiro",
	},
	records: {
		phase: "Estágio",
		push_red: "Cor vermelha recomendada",
		push_blue: "Cor azul recomendada",
		odd: "As probabilidades",
		opened: "Aberto",
		not_opened: "Ainda não abriu",
		result: "usuário.resultado",
		red_victory: "Vermelho vence",
		blue_victory: "Vitórias verdes",
		red_profit: "Lucro no lado vermelho",
		blue_profit: "Lucros do lado verde",
	},

	recharge: {
		placeholder1: "Por favor insira o valor a depositar",
		placeholder2: "Por favor, carregue seu comprovante de depósito",
		upload_successfully: "Enviado com sucesso",
		tip1: "O tamanho do arquivo não deve exceder 6 MB",
		submitted_successfully: "submetido com sucesso",
		tip2: "Você tem uma solicitação de depósito em análise. Aguarde um momento",
	},
	bank: {
		bank_card_management: "Gerenciar cartões bancários",
		placeholder1: "Por favor insira o nome do banco",
		placeholder2: "número da conta de banco",
		placeholder3: "Por favor insira o nome do destinatário",
		label1: "nome do banco",
		label2: "número da conta do destinatário",
		label3: "nome do destinatário",
		add_successfully: "Mais sucesso",
		add_failure: "Mais fracasso",
		card_number_error: "O número do cartão não está no formato exigido",
	},
	safety: {
		text1: "Alterar senha de saque",
		text2: "Por favor insira a senha antiga",
		text3: "Por favor insira uma nova senha",
		text4: "Por favor confirme sua nova senha",
		modified_successfully: "Mudança bem-sucedida",
		old_password_error: "A senha antiga está incorreta",
		title: "Centro de Traição",
	},
	register: {
		title: "Cadastro de usuário",
		input_placeholder1: "Por favor insira o nome de usuário",
		input_placeholder2: "Por favor insira uma senha",
		input_placeholder3: "Por favor, digite sua senha novamente",
		input_placeholder4: "Por favor insira o código de referência",
		register: "Registro",
		text1: "já tem uma conta?",
		to_login: "Conecte-se agora",
		toast1: "A senha digitada novamente não corresponde",
	},
	index: {
		title: "Letra da música",
		text1: "Detalhes da notificação",
		period_time: "Espere",
		deadline_participation: "Prazo para participar",
		closed: "Fechar",
		deadline: "Prazo para entrega de prêmios",
		min_bet_amount: "Valor mínimo da aposta",
		number_one: "Número de item",
		red_team: "Equipa vermelha",
		blue_team: "Time azul",
		red_team_odds: "Probabilidades do time vermelho",
		blue_team_odds: "Probabilidades do time azul",
		all: "Todos",
		get_involved_now: "Entrar",
		placeholder1: "Por favor insira o valor da aposta",
	},
	login: {
		title: "por favor entre",
		tip1: "Por favor insira o nome de usuário",
		tip2: "Por favor insira uma senha",
		forget_password: "Esqueceu sua senha",
		submit: "Enviar",
		text1: "Nenhuma conta",
		to_register: "Registro",
		change_login_password: "Alterar senha de login",
	},
	user: {
		withdraw_password: "Senha de retirada",
		login_password: "Senha de login",
		account_security: "segurança de senha",
		rejected: "Negado",
		passed: "Passado",
		pending_review: "Aguardando revisão",
		state: "Status",
		time: "Tempo",
		amount: "Quantia de dinheiro",
		types: "Tipo",
		withdraw_funds: "Retirar dinheiro",
		recharge: "Recarrega",
		no_more: "Não mais",
		loading: "Carregando...",
		account: "Conta",
		invitation_code: "Código de convite",
		balance: "Excedente",
		endowment: "Fundo de caridade",
		withdraw_cash_now: "Retirar dinheiro",
		go_recharge: "Recarrega",
		tiktok_shop: "Loja TikTok",
		security_center: "Centro de Segurança",
		bank_record: "Registros bancários",
		transaction_records: "Registros de transação",
		bank_card: "cartão do banco",
		language: "Linguagem",
		logout: "Sair",
		go_setting: "Vá para as configurações"
	},
};
